import { mutationBuilder, queryBuilder } from '@innovatrix/utils/dx';

export const createWorkshopTypeMutation = mutationBuilder({
  variables: {
    workshopType: { type: 'JSON!' },
  },
  fields: {
    _createWorkshopType: {
      varArgs: { workshopType: 'workshopType' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const createWorkshopMomentMutation = mutationBuilder({
  variables: {
    description: { type: 'String' },
    name: { type: 'String!' },
    workshopId: { type: 'String!' },
  },
  fields: {
    _createWorkshopMoment: {
      varArgs: { description: 'description', name: 'name', workshopId: 'workshopId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const deleteProjectTimeslotMutation = mutationBuilder({
  variables: { id: { type: 'String!' } },
  fields: {
    _deleteProjectTimeslot: {
      varArgs: { id: 'id' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const getProjectTimeslotsQuery = queryBuilder({
  variables: {
    timeslotTimeRangeId: { type: 'String!' },
  },
  fields: {
    _getProjectTimeslots: {
      varArgs: { timeslotTimeRangeId: 'timeslotTimeRangeId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const createProjectTimeslotMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    startDatetime: { type: 'Date!' },
    endDatetime: { type: 'Date!' },
    timeslotTimeRangeId: { type: 'String!' },
  },
  fields: {
    _createProjectTimeslot: {
      varArgs: {
        endDatetime: 'endDatetime',
        projectId: 'projectId',
        startDatetime: 'startDatetime',
        timeslotTimeRangeId: 'timeslotTimeRangeId',
      },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const manageWorkshopMomentsMutation = mutationBuilder({
  variables: {
    workshopMoments: { type: 'JSON!' },
    workshopMomentsToDelete: { type: 'JSON!' },
  },
  fields: {
    _manageWorkshopMoments: {
      varArgs: { workshopMoments: 'workshopMoments', workshopMomentsToDelete: 'workshopMomentsToDelete' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const manageWorkshopTypesMutation = mutationBuilder({
  variables: {
    workshopTypes: { type: 'JSON!' },
    workshopTypesToMove: { type: 'JSON!' },
  },
  fields: {
    _manageWorkshopTypes: {
      varArgs: { workshopTypes: 'workshopTypes', workshopTypesToMove: 'workshopTypesToMove' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const moveWorkshopMutation = mutationBuilder({
  variables: {
    workshopId: { type: 'String!' },
    workshopTypeId: { type: 'String!' },
  },
  fields: {
    _moveWorkshop: {
      varArgs: { workshopTypeId: 'workshopTypeId', workshopId: 'workshopId' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const createWorkshopMutation = mutationBuilder({
  variables: {
    workshop: { type: 'JSON!' },
  },
  fields: {
    _createWorkshop: {
      varArgs: { workshop: 'workshop' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const updateWorkshopMutation = mutationBuilder({
  variables: {
    workshop: { type: 'JSON!' },
  },
  fields: {
    _updateWorkshop: {
      varArgs: { workshop: 'workshop' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const updateWorkshopMomentMutation = mutationBuilder({
  variables: { id: { type: 'String!' }, workshopMoment: { type: 'JSON!' } },
  fields: {
    _updateWorkshopMoment: {
      varArgs: { id: 'id', workshopMoment: 'workshopMoment' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const getWorkshopMomentsQuery = queryBuilder({
  variables: { workshopId: { type: 'String!' } },
  fields: {
    _getWorkshopMoments: {
      varArgs: { workshopId: 'workshopId' },
      fields: {
        result: true,
        success: true,
        error: true,
      },
    },
  },
});

export const inviteProjectToWorkshopMutation = mutationBuilder(
  {
    variables: {
      projectIds: { type: '[String]!' },
      workshopMomentId: { type: 'String!' },
    },
    fields: {
      _inviteProjectToWorkshop: {
        varArgs: {
          projectIds: 'projectIds',
          workshopMomentId: 'workshopMomentId',
        },
        fields: {
          success: true,
          error: true,
        },
      },
    },
  },
);

export const updateInvitationStatusMutation = mutationBuilder({
  variables: {
    projectId: { type: 'String!' },
    status: { type: 'String!' },
    reason: { type: 'String' },
    workshopMomentId: { type: 'String!' },
  },
  fields: {
    _updateInvitationStatus: {
      varArgs: { projectId: 'projectId', status: 'status', workshopMomentId: 'workshopMomentId', reason: 'reason' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const requireProjectToWorkshopMutation = mutationBuilder(
  {
    variables: {
      projectId: { type: 'String!' },
      required: { type: 'Boolean!' },
      workshopMomentId: { type: 'String!' },
    },
    fields: {
      _requireProjectToWorkshop: {
        varArgs: {
          projectId: 'projectId',
          required: 'required',
          workshopMomentId: 'workshopMomentId',
        },
        fields: {
          success: true,
          error: true,
        },
      },
    },
  },
);

export const deleteWorkshopMutation = mutationBuilder(
  {
    variables: {
      workshopId: { type: 'String!' },
    },
    fields: {
      _deleteWorkshop: {
        varArgs: {
          workshopId: 'workshopId',
        },
        fields: {
          success: true,
          error: true,
        },
      },
    },
  },
);

export const deleteWorkshopDateMutation = mutationBuilder(
  {
    variables: {
      workshopDateId: { type: 'String!' },
    },
    fields: {
      _deleteWorkshopDate: {
        varArgs: {
          workshopDateId: 'workshopDateId',
        },
        fields: {
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getProjectsForWorkshopQuery = queryBuilder(
  {
    variables: {
      workshopMomentId: { type: 'String!' },
    },
    fields: {
      _getProjectsForWorkshop: {
        varArgs: { workshopMomentId: 'workshopMomentId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getWorkshopsQuery = queryBuilder(
  {
    variables: {
      workshopTypeId: { type: 'String!' },
    },
    fields: {
      _getWorkshops: {
        varArgs: { workshopTypeId: 'workshopTypeId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getWorkshopTypesQuery = queryBuilder(
  {
    fields: {
      _getWorkshopTypes: {
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const createWorkshopDateMutation = mutationBuilder({
  variables: {
    date: { type: 'String!' },
    end: { type: 'String' },
    location: { type: 'JSON' },
    start: { type: 'String' },
    workshopMomentId: { type: 'String!' },
    extraInformation: { type: 'String' },
    meetingLink: { type: 'String' },
  },
  fields: {
    _createWorkshopDate: {
      varArgs: { date: 'date', end: 'end', location: 'location', start: 'start', workshopMomentId: 'workshopMomentId', extraInformation: 'extraInformation', meetingLink: 'meetingLink' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const updateWorkshopDateMutation = mutationBuilder({
  variables: {
    date: { type: 'String!' },
    end: { type: 'String' },
    location: { type: 'JSON' },
    start: { type: 'String' },
    id: { type: 'String!' },
    extraInformation: { type: 'String' },
    meetingLink: { type: 'String' },
  },
  fields: {
    _updateWorkshopDate: {
      varArgs: { date: 'date', end: 'end', id: 'id', location: 'location', start: 'start', extraInformation: 'extraInformation', meetingLink: 'meetingLink' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const getWorkshopDatesQuery = queryBuilder(
  {
    variables: {
      workshopMomentId: { type: 'String!' },
    },
    fields: {
      _getWorkshopDates: {
        varArgs: { workshopMomentId: 'workshopMomentId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const getTimeslotDatesQuery = queryBuilder(
  {
    variables: {
      workshopMomentId: { type: 'String!' },
    },
    fields: {
      _getTimeslotDates: {
        varArgs: { workshopMomentId: 'workshopMomentId' },
        fields: {
          result: true,
          success: true,
          error: true,
        },
      },
    },
  },
);

export const createTimeslotDateMutation = mutationBuilder({
  variables: {
    date: { type: 'String!' },
    workshopMomentId: { type: 'String!' },
    interval: { type: 'Float' },
    location: { type: 'JSON' },
  },
  fields: {
    _createTimeslotDate: {
      varArgs: { location: 'location', date: 'date', workshopMomentId: 'workshopMomentId', interval: 'interval' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const updateTimeslotDateMutation = mutationBuilder({
  variables: {
    date: { type: 'String!' },
    extraInformation: { type: 'String' },
    id: { type: 'String!' },
    interval: { type: 'Float' },
    location: { type: 'JSON' },
    workshopMomentId: { type: 'String' },
  },
  fields: {
    _updateTimeslotDate: {
      varArgs: {
        date: 'date',
        extraInformation: 'extraInformation',
        id: 'id',
        interval: 'interval',
        location: 'location',
        workshopMomentId: 'workshopMomentId',
      },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const deleteTimeslotDateMutation = mutationBuilder({
  variables: {
    id: { type: 'String!' },
  },
  fields: {
    _deleteTimeslotDate: {
      varArgs: { id: 'id' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const createTimeslotTimeRangeMutation = mutationBuilder({
  variables: {
    endDatetimes: { type: 'JSON' },
    startDatetimes: { type: 'JSON' },
    timeslotDateId: { type: 'String!' },
  },
  fields: {
    _createTimeslotTimeRange: {
      varArgs: { endDatetimes: 'endDatetimes', startDatetimes: 'startDatetimes', timeslotDateId: 'timeslotDateId' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const updateTimeslotTimeRangeMutation = mutationBuilder({
  variables: {
    endDatetime: { type: 'Date!' },
    id: { type: 'String!' },
  },
  fields: {
    _updateTimeslotTimeRange: {
      varArgs: { endDatetime: 'endDatetime', id: 'id' },
      fields: {
        result: true,
        error: true,
        success: true,
      },
    },
  },
});

export const deleteAttachedWorkshopMutation = mutationBuilder({
  variables: {
    workshopMomentId: { type: 'String!' },
    documentId: { type: 'String!' },
  },
  fields: {
    _deleteAttachedDocument: {
      varArgs: { workshopMomentId: 'workshopMomentId', documentId: 'documentId' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const deleteAttachedDocumentForRevisionMutation = mutationBuilder({
  variables: {
    documentId: { type: 'String!' },
    forRevision: { type: 'Boolean!' },
    onlyDeleteFile: { type: 'Boolean!' },
    workshopMomentId: { type: 'String!' },
  },
  fields: {
    _deleteAttachedDocumentForRevision: {
      varArgs: { documentId: 'documentId', forRevision: 'forRevision', onlyDeleteFile: 'onlyDeleteFile', workshopMomentId: 'workshopMomentId' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});

export const rejectRevisedDocumentMutation = mutationBuilder({
  variables: {
    documentId: { type: 'String!' },
    projectId: { type: 'String!' },
    workshopMomentId: { type: 'String!' },
  },
  fields: {
    _rejectRevisedDocument: {
      varArgs: { workshopMomentId: 'workshopMomentId', projectId: 'projectId', documentId: 'documentId' },
      fields: {
        error: true,
        success: true,
      },
    },
  },
});

export const deleteRevisedDocumentMutation = mutationBuilder({
  variables: {
    documentId: { type: 'String!' },
    projectId: { type: 'String!' },
    workshopMomentId: { type: 'String!' },
  },
  fields: {
    _deleteRevisedDocument: {
      varArgs: { documentId: 'documentId', projectId: 'projectId', workshopMomentId: 'workshopMomentId' },
      fields: {
        success: true,
        error: true,
      },
    },
  },
});
