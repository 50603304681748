import axios from 'axios';
import Papa from 'papaparse';
import { fetchQuery } from '@innovatrix/react-frontend/queries';
import { getMimeType } from '@innovatrix/utils';
import * as toast from '@innovatrix/utils/toast';

export async function fetch(field, query, args, isDxApi) {
  const result = await fetchQuery(query, args);
  const { complete, data, error } = result;
  if (error) {
    throw error;
  }
  if (complete === false) {
    const notCompletedError = new Error('Request not completed.');
    notCompletedError.code = 'NOT_COMPLETED';
    throw notCompletedError;
  }
  if (!data) {
    throw new Error('No data available.');
  }
  if (!data[field]) {
    throw new Error(`Field ${field} does not exist in server payload.`);
  }
  if (data[field].error && !isDxApi) {
    throw data[field].error;
  }
  if (!data[field].success && !isDxApi) {
    console.error('Server returned no error or success.');
    throw new Error('Unexpected error.');
  }
  return isDxApi ? data[field] : data[field].result;
}

/**
 * Download raw blob
 *
 * @param {string} data file data e.g. CSV string
 * @param {string} fileName output filename
 */
export async function downloadRawFile(data, fileName) {
  // see also https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([data]));
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Trigger download of the file at specified URL.
 */
export async function downloadFile(authToken, url, formData, requestType = 'POST') {
  try {
    const response = await axios({
      data: formData,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'multipart/form-data',
      },
      method: requestType,
      responseType: 'blob', // important
      url,
    });

    // If there is no content, then show a toast to the user
    // Code 204 => "No Content" status
    if (response.status === 204) {
      toast.success('There is nothing to download.');
    }
    else {
      const fileName = formData.get('name');
      await downloadRawFile(response.data, fileName);
    }
  }
  catch (e) {
    throw new Error(e);
  }
}

/**
 * Preview of the file in the browser.
 */
export async function previewFile(authToken, url, formData, requestType = 'POST') {
  const response = await axios({
    data: formData,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    },
    method: requestType,
    responseType: 'blob', // important
    url,
  });
  const fileName = formData.get('name');
  const mimeType = getMimeType(fileName);
  const blob = response.data.slice(0, response.data.size, mimeType);
  const blobURL = URL.createObjectURL(blob);
  window.open(blobURL, fileName);
  return blobURL;
}

/**
 * Download CSV file
 *
 * @param {array} csvData homogeneous array of objects
 * @param {string} fileName output filename
 */
export async function downloadCsvFile(csvData, fileName) {
  // assume all CSV files will want to include a header
  // @link https://www.papaparse.com/docs#config
  await downloadRawFile(Papa.unparse(csvData), fileName);
}

export async function uploadFile(authToken, url, formData) {
  return await axios.post(url, formData, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'multipart/form-data',
    },
  });
}

